.max-w-50 {
    max-width: 50%;
}
.max-w-75 {
    max-width: 75%;
}

.max-height-100vh {
    max-height: 100vh;
}

.max-height-95vh {
    max-height: 95vh;
}

.max-width-100vw {
    max-width: 100vw;
}

.custom_size {
    width: 3em;
    height: 3em ;
}

.h-90vh {
    height: 90vh;
}