body {
  box-sizing: border-box;
  /* color: #ffffff; */
  font-family: 'Poppins', sans-serif;
}

/* .container {
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 70px;
  } */

.title {
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr th {
  font-size: 18px;
  padding: 12px;
  border: 1px solid #FF0000;
  text-align: left;
  background-color: #FF0000;
  color: #FFFF;
}

tr td {
  border: 1px solid #eeeeee;
  text-align: left;
}

input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  width: 91%;
  /* padding: 12px 12px; */
  font-family: 'Poppins', sans-serif;
}

input:hover {
  /* background-color: #fffaf3; */
}

input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}

.roundColor {
  background: #FDC925 !important;
  color: white !important;
}

.roundColor input {
  background: #FDC925 !important;
  color: #000000 !important;

  text-align: center;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #FF0000;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
  box-shadow: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.b-white {
  border-color: #fff !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.roundColor .not_valid,
.roundColor .not-valid-ws {
  background-color: #ff0000c9 !important;
  color: #fff !important;
}

.b-red,
.b-red:focus {
  border-color: #ff0000c9 !important;
}

.score_label {
  border: 1px solid #00000030;
  width: 4rem;
  height: 4rem;
  float: left;
  margin-left: .5em;
  text-align: center;
  line-height: 3;
  border-radius: 5px;
  color: #583b3b;
  font-size: 1.4em;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  cursor: pointer;
  margin-bottom: 1em;
}

.d-flow-root {
  display: flow-root !important;
}

.w-fit-content {
  width: fit-content !important;
}

.box-shadow {
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:disabled,
.cursor-pointer.disabled {
  cursor: default;
}

.fw-6 {
  font-weight: 600;
}

.w-55 {
  width: 55%;
}

.w-40 {
  width: 40%;
}

.w-6 {
  width: 6%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-8 {
  width: 8%;
}

.lh-2 {
  line-height: 2;
}

.w-15p {
  width: 15px;
}

.custominput input:focus {
  outline: none;
  border: none;
}

.opacity-0 {
  opacity: 0;
}

.score_btn_spide {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 22px;
}

.bi {
  transition: all .2s ease-in-out;
}

i.disabled {
  background-color: #0000007a !important;
}

.bg_light_gray {
  background-color: #8080804f;
}

.bg_dark_gray {
  background-color: #323232 !important;
}

.select_chevron_down::before {
  content: "\f282";
}

.mb-004 {
  margin-bottom: -4px;
  ;
}

.mt-50 {
  margin-top: 50%;
}

.t-50 {
  top: 50%;
}

.t506 { 
  top:5.6em !important;
}

.h-0 {
  height: 0 !important;
}

.bi-chevron-down::before {
  transition: all .2s ease-in-out;
}

.rotate.bi-chevron-down::before {
  transform: rotate(-180deg);
}

.ajax_datatable_round_loader {
  z-index: 99;
  top: 3.2em;
  left: 0;
  right: 0;
  bottom: 3.3em;
  background: #00000052;
}

.custom_close_btn_header .btn-close {
  margin:0 !important;
}