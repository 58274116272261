@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,600;1,800&display=swap');


/* @font-face {
    font-family: RenoMono;
    src: url("../font/RenoMono.otf") format("opentype");
} */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Noto Sans', sans-serif;
    text-transform: capitalize;
}

.form-control:focus {
    box-shadow: none;
}

#wrapper {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    color: #344A5D;
}

#sidebar-wrapper .list-group {
    width: 15rem;
    transition: 0.2s;

}

#sidebar-wrapper .list-group .MuiSkeleton-root {
    background-color:#ffffff59;
}

#page-content-wrapper {
    min-width: 100vw;
}

body.flx-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: 0;
}

body.flx-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-list a span,
body.flx-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-heading span {
    transition: 0.5s;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    body.flx-sidenav-toggled #wrapper #sidebar-wrapper {
        margin-left: -0rem;
        overflow: hidden;
    }

    body.flx-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-list a span,
    body.flx-sidenav-toggled #wrapper #sidebar-wrapper .sidebar-heading span {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }

    body.flx-sidenav-toggled #wrapper #sidebar-wrapper .list-group {
        width: 4.3rem;
        overflow: hidden;
    }
}

#sidebar-wrapper a.active {
    background-color: #D51520;
    color: #fff;
    font-weight: bold;
}

#sidebar-wrapper a i {
    margin-left: 10px !important;
}

#sidebar-wrapper a.active i {
    -webkit-text-stroke: 0.5px;
}

#sidebar-wrapper a {
    font-size: 18px;
    color: #fff;
    background-color: #000000;
    text-transform: capitalize;
}

#sidebar-wrapper a:hover {
    background-color: #D51520;
    color: #fff;
}

#page-content-wrapper>main {
    overflow: auto;
    /* height: 100vh; */
}

#page-content-wrapper>main::-webkit-scrollbar {
    display: none;
}

/* Vijay Css Start*/
.top_bar_logo {
    width: 3em;
}


.dashboard_card_box .card {
    background-repeat: no-repeat;
    background-position: left;
    background-size: 3em auto;
    background-position: 1em;
    padding-left: 4em !important;
}

.card-header {
    display: flex;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.625rem;
    height: 3.5rem;
    background-color: #fff;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.btn-actions-pane-right {
    margin-left: auto;
    white-space: nowrap;
}

.card-header,
.card-title {
    text-transform: uppercase;
    color: rgba(13, 27, 62, 0.7);
    font-weight: bold;
    font-size: .88rem;
}

.dashboard_datatable_box .dataTables_length,
.dashboard_datatable_box .dataTables_filter {
    display: none;
}

#root {
    background-color: #f1f4f6;
}

.table thead th {
    background: #d51520;
    border-color: #d51520;
    outline: none;
    color: #fff;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

table#example tbody tr:nth-of-type(odd) {
    background-color: rgb(0 0 0 / 14%);
}

.w_20 {
    width: 20%;
}

.table_profile {
    width: 3em;
}

.w-fitContent {
    width: fit-content !important;
}

.background_image {
    width: 1.5em;
    height: 1.5em;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

/* Vijay Css End */

/* Ps */

#example_length,
#example_filter {
    margin-bottom: 10px;
}


/* Datatabele */
/* end os */

.rdt_TableHeadRow {
    background-color: red !important;
    color: #fff !important;
    font-weight: bold;
}

.dwPlXY {
    border-left: 1px solid #D3D3D3;
    border-right: 1px solid #D3D3D3;
}

.dwPlXY:nth-child(even) {
    background-color: #D3D3D3 !important;
}

.sc-eDWCr.hbotQl {
    font-size: 17px;
}

.dtqzBx.rdt_TableCell {
    font-size: 15px;
}

.sc-dIfARi.kDEcCH {
    padding: 0;
}

.withoutselectDatatable .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(1),
.withoutselectDatatable .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
    max-width: 20px;
}

.select-search-options {
    padding: 0;
}
.ck-editor__editable_inline {
    min-height: 250px;
}
.scrollbar-danger::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
  }
  
  .scrollbar-danger::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }
  
  .scrollbar-danger::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ff3547;
  }
  
  .scrollbar-danger {
    scrollbar-color: #ff3547 #F5F5F5;
  }
  .chatImage{
    width: 45px!important;
    height: 100%!important;
  }
  .bg-chatImage-left{
    background-color: #5A99EE;
  }
  .bg-chatImage-right{
    background-color: #FC6D4C;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .privacy-policy h2 ,.privacy-policy h5 ,.privacy-policy h6 {
    color: #DC3545;
      }