.bg-danger,
.btn-danger {
    background-color: #D51520 !important;
}

.text-danger {
    color: #D51520;
}

.bg-dark,
.btn-dark {
    background-color: #000000 !important;
}

.bg-warning,
.btn-warning {
    background-color: #FFC724 !important;
}


.h-100vh{
    height: 100vh;
}
/* padding */
.p-6 {
    padding: 4rem;
}

.p-7 {
    padding: 5rem;
}

.p-8 {
    padding: 6rem;
}

.p-9 {
    padding: 7rem;
}

.p-10 {
    padding: 8rem;
}

.pp-5 {
    padding: 5px;
}

.pp-10 {
    padding: 10px;
}

.pp-15 {
    padding: 15px;
}

.pp-20 {
    padding: 20px;
}

.pp-25 {
    padding: 25px;
}

.pp-30 {
    padding: 30px;
}


/* padding-top */
.pt-6 {
    padding-top: 4rem;
}

.pt-7 {
    padding-top: 5rem;
}

.pt-8 {
    padding-top: 6rem;
}

.pt-9 {
    padding-top: 7rem;
}

.pt-10 {
    padding-top: 8rem;
}

/* padding-bottom */
.pb-6 {
    padding-bottom: 4rem;
}

.pb-7 {
    padding-bottom: 5rem;
}

.pb-8 {
    padding-bottom: 6rem;
}

.pb-9 {
    padding-bottom: 7rem;
}

.pb-10 {
    padding-bottom: 8rem;
}

/* padding-left */
.ps-6 {
    padding-left: 4rem;
}

.ps-7 {
    padding-left: 5rem;
}

.ps-8 {
    padding-left: 6rem;
}

.ps-9 {
    padding-left: 7rem;
}

.ps-10 {
    padding-left: 8rem;
}

/* padding-right */
.pe-6 {
    padding-right: 4rem;
}

.pe-7 {
    padding-right: 5rem;
}

.pe-8 {
    padding-right: 6rem;
}

.pe-9 {
    padding-right: 7rem;
}

.pe-10 {
    padding-right: 8rem;
}

/* padding-top-bottom */
.py-2_5 {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.py-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.py-7 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.py-8 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.py-9 {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.py-10 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}


.pyp-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pyp-11 {
    padding-top: 11px;
    padding-bottom: 11px;
}

.pyp-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.pyp-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.pyp-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pyp-21 {
    padding-top: 21px;
    padding-bottom: 21px;
}

.pyp-22 {
    padding-top: 22px;
    padding-bottom: 22px;
}

.pyp-23 {
    padding-top: 23px;
    padding-bottom: 23px;
}

.pyp-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.pyp-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.pyp-26 {
    padding-top: 26px;
    padding-bottom: 26px;
}

.pyp-27 {
    padding-top: 27px;
    padding-bottom: 27px;
}

.pyp-28 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.pyp-29 {
    padding-top: 29px;
    padding-bottom: 29px;
}

.pyp-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

/* padding-left-right */

.px-6 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.px-7 {
    padding-left: 5rem;
    padding-right: 5rem;
}

.px-8 {
    padding-left: 6rem;
    padding-right: 6rem;
}

.px-9 {
    padding-left: 7rem;
    padding-right: 7rem;
}

.px-10 {
    padding-left: 8rem;
    padding-right: 8rem;
}

@media (min-width: 768px) {
    .px-md-8 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
}

@media (min-width: 992px) {
    .px-lg-8 {
        padding-right: 6rem !important;
        padding-left: 6rem !important;
    }
}


/* padding */
.m-6 {
    margin: 4rem;
}

.m-7 {
    margin: 5rem;
}

.m-8 {
    margin: 6rem;
}

.m-9 {
    margin: 7rem;
}

.m-10 {
    margin: 8rem;
}

/* padding-top */
.mt-2_5 {
    margin-top: 0.7rem;
}

.mt-6 {
    margin-top: 4rem;
}

.mt-7 {
    margin-top: 5rem;
}

.mt-8 {
    margin-top: 6rem;
}

.mt-9 {
    margin-top: 7rem;
}

.mt-10 {
    margin-top: 8rem;
}

/* padding-bottom */
.mb-6 {
    margin-bottom: 4rem;
}

.mb-7 {
    margin-bottom: 5rem;
}

.mb-8 {
    margin-bottom: 6rem;
}

.mb-9 {
    margin-bottom: 7rem;
}

.mb-10 {
    margin-bottom: 8rem;
}

.mb-n1 {
    margin-bottom: -0.5rem;
}

.mb-n2 {
    margin-bottom: -1rem;
}

.mb-n3 {
    margin-bottom: -3rem;
}

.mb-n4 {
    margin-bottom: -4rem;
}

/* padding-left */
.ms-6 {
    margin-left: 4rem;
}

.ms-7 {
    margin-left: 5rem;
}

.ms-8 {
    margin-left: 6rem;
}

.ms-9 {
    margin-left: 7rem;
}

.ms-10 {
    margin-left: 8rem;
}

.ms-n1 {
    margin-left: -0.5rem;
}

.ms-n2 {
    margin-left: -1rem;
}

.ms-n3 {
    margin-left: -1.5rem;
}

.ms-n4 {
    margin-left: -2rem;
}

.ms-n5 {
    margin-left: -2.5rem;
}

@media(max-width:992px) {
    .ms-n4 {
        margin-left: 0;
    }
}

/* padding-right */
.me-6 {
    margin-right: 4rem;
}

.me-7 {
    margin-right: 5rem;
}

.me-8 {
    margin-right: 6rem;
}

.me-9 {
    margin-right: 7rem;
}

.me-10 {
    margin-right: 8rem;
}

/* padding-top-bottom */
.my-6 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.my-7 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.my-8 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.my-9 {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.my-10 {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

/* margin-left-right */
.mx-6 {
    margin-left: 4rem;
    margin-right: 4rem;
}

.mx-7 {
    margin-left: 5rem;
    margin-right: 5rem;
}

.mx-8 {
    margin-left: 6rem;
    margin-right: 6rem;
}

.mx-9 {
    margin-left: 7rem;
    margin-right: 7rem;
}

.mx-10 {
    margin-left: 8rem;
    margin-right: 8rem;
}

/* Top */

.top-5 {
    top: 5%;
}

.top-10 {
    top: 10%;
}

.top-15 {
    top: 15%;
}

.top-20 {
    top: 20%;
}

.top-25 {
    top: 25%;
}

.top-30 {
    top: 30%;
}

/*  End start top bottom */

.start-25 {
    left: 25%;
}

.start-60 {
    left: 60%;
}

.start-65 {
    left: 65%;
}

.start-75 {
    left: 75%;
}

.start-70 {
    left: 70%;
}

/* font size */

.fs-7 {
    font-size: 3rem;
}

.fs-8 {
    font-size: 4rem;
}

.fs-9 {
    font-size: 4.5rem;
}

.fs-10 {
    font-size: 5rem;
}

.fs-11 {
    font-size: 5.5rem;
}

/* colers */

.text-primary-100 {
    color: #3F8ECE;
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.fw-n {
    font-weight: normal;
}



/* font size */

.fsp-8 {
    font-size: 8px;
}

.fsp-9 {
    font-size: 9px;
}

.fsp-10 {
    font-size: 10px;
}

.fsp-11 {
    font-size: 11px;
}

.fsp-12 {
    font-size: 12px;
}

.fsp-13 {
    font-size: 13px;
}

.fsp-14 {
    font-size: 14px;
}

.fsp-15 {
    font-size: 15px;
}

.fsp-16 {
    font-size: 16px;
}

.fsp-17 {
    font-size: 17px;
}

.fsp-18 {
    font-size: 18px;
}

.fsp-19 {
    font-size: 19px;
}

.fsp-20 {
    font-size: 20px;
}

.fsp-21 {
    font-size: 21px;
}

.fsp-22 {
    font-size: 22px;
}

.fsp-23 {
    font-size: 23px;
}

.fsp-24 {
    font-size: 24px;
}

.fsp-25 {
    font-size: 25px;
}

.fsp-26 {
    font-size: 26px;
}

.fsp-27 {
    font-size: 27px;
}

.fsp-28 {
    font-size: 28px;
}

.fsp-29 {
    font-size: 29px;
}

.fsp-30 {
    font-size: 30px;
}

.fsp-31 {
    font-size: 31px;
}

.fsp-32 {
    font-size: 32px;
}

.fsp-33 {
    font-size: 33px;
}


.fsp-34 {
    font-size: 34px;
}

.fsp-35 {
    font-size: 35px;
}

.fsp-45 {
    font-size: 45px;
}


.bottom-n-1 {
    bottom: -3px;
}

.bottom-n-2 {
    bottom: -6px;
}

.bottom-n-3 {
    bottom: -9px;
}

/* width */

.w-10 {
    width: 10px;
}

.w-15 {
    width: 15px;
}

.w-16 {
    width: 16px;
}

.w-18 {
    width: 18px;
}

.w-20 {
    width: 19px;
}

.w-20 {
    width: 19px;
}

.w-30 {
    width: 30px;
}

.w-40 {
    width: 40px;
}

.wp-50 {
    width: 50px;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-sm::-webkit-scrollbar {
    width: 10px;
}



/* background */

.bg-tr-dark-500 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-tr-dark-600 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-tr-dark-700 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-tr-dark-800 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-tr-dark-900 {
    background-color: rgba(0, 0, 0, 0.9);
}

.bg-tr-dark-1000 {
    background-color: rgba(0, 0, 0, 1);
}

.custom-spinner {
    height: 1.2em;
    width: 1.2em;
    vertical-align: sub;
}